// Global styles

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
   box-sizing: border-box;
}

body {
  font-size: 14px;
}

a {
  color: inherit;
}

h2 {
  text-align: center;
  font-weight: 400;
  font-size: 32px;
  font-weight: 300;
}

.cover {
  content: var(--cover-image);
}

.header .content {
  margin: 6% 8%;
  text-align: center;

  a {
    text-decoration: none;
    color: var(--foreground);
  }
  
  h3, h4 {
    font-weight: 400;
    font-size: 20px;
    margin: 20px;
  }
  
  h3 {
    color: var(--light-green);
  }

  h4 {
    display: inline-block;
    border: 2px solid var(--red);
    padding: 12px 32px;
    margin: 12px;
    border-radius: 9999px;

    &.accent {
      background-color: var(--red);
      color: white;
    }
  }
}

.section {
  padding: 24px;
}

.schedule {
  background-color: var(--blue);

  .events {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

.Event {
  background-color: var(--background);
  box-shadow: rgba(17, 12, 46, 0.15) 0px 32px 64px 0px;
  padding: 24px;
  margin: 24px;
  border-radius: 24px;

  .header {
    display: flex;
    align-items: center;

    .date {
      flex-grow: 1;
      background: var(--red);
      border-radius: 8px;
      min-width: 56px; min-height: 56px;
      max-width: 56px; max-height: 56px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      h4, h5 {
        color: white;
        margin: -6px;
        font-weight: 600
      }

      h4 {
        font-size: 32px;
      }

      h5 {
        font-size: 14px;
        margin-top: -2px;
      }
    }

    .text {
      margin-left: 16px;

      h3, h5 { margin: 0; }

      h3 { font-size: 20px; font-weight: 500; }
      h5 { font-size: 14px; font-weight: 400; }
    }
  }
}

.questions {
  display: grid;
  justify-content: center;
  grid-gap: 48px;
  padding: 24px;
  grid-template-columns: repeat(auto-fit, 256px);
}

.Question {
  cursor: pointer;
  height: 256px;
  background-color: var(--dark-green);
  box-shadow: rgba(17, 12, 46, 0.15) 0px 32px 64px 0px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 24px;
  text-align: center;

  &.answered {
    background-color: var(--secondary-background);
    color: var(--light-green);
  }
}

.copyright {
  color: var(--light-green);
  text-align: center;
}
